import React, { Fragment, useContext, useState } from "react";
import classNames from "classnames";
import { FreeTrialRole, useUserState } from "gx-npm-lib";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import {
  ButtonLoader,
  FreeTrialInitCreateModalComponent,
  TooltipV2,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { createInitiativeFromTemplateV3 } from "../../../context/actions/templateActions";
import styles from "./styles";
import { GCOM_3606__fontUpdate } from "../../../lib/feature-flags";

const propTypes = {
  canUseTemplate: PropTypes.bool,
  categoryName: PropTypes.string,
  categoryId: PropTypes.number,
  isAtTop: PropTypes.bool,
  isSearchData: PropTypes.bool,
  searchResultsCount: PropTypes.number,
  searchTerm: PropTypes.string,
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  userInitCount: PropTypes.number,
};
const useStyles = makeStyles(() => styles);
const TemplateHeader = ({
  canUseTemplate = false,
  categoryName = "",
  categoryId = 0,
  isAtTop = false,
  isSearchData = false,
  searchResultsCount = 0,
  searchTerm = "",
  templateId = 0,
  templateName = "",
  userInitCount = 0,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] = useState(false);
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { freeTrialRole } = useUserState();
  const isTrialUser = freeTrialRole === FreeTrialRole.FREE_TRIAL_RECIPIENT;

  const name = state?.template?.overview?.data?.name;
  const isLoading = state?.template?.createInitiative?.isLoading;

  const handleCloseFreeTrialModal = () => {
    setIsFreeTrialModalOpen(false);
  };

  const handleConfirmFreeTrialModal = async () => {
    const config = {
      categoryId,
      categoryName,
      isSearchData,
      searchResultsCount,
      searchTerm,
      templateId,
      templateName,
    };
    await createInitiativeFromTemplateV3(dispatch, config);
  };

  const handleClickTemplateButton = async () => {
    if (isTrialUser) {
      setIsFreeTrialModalOpen(true);
    } else {
      const config = {
        categoryId,
        categoryName,
        isSearchData,
        searchResultsCount,
        searchTerm,
        templateId,
        templateName,
      };
      await createInitiativeFromTemplateV3(dispatch, config);
    }
  };

  return (
    <Fragment>
      <div className={classNames(classes.container, !isAtTop && classes.boxShadow)}>
        <div className={classes.templateHeaderContainer}>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                color={"carbon"}
                rootClassName={classNames(classes.templateHeaderGCOM3606)}
                styling={"h2"}
                boldness={"semi"}
              >
                {name}
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <div className={classes.templateHeader}>{name}</div>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>

          <div className={classes.usetTemplateContainer}>
            <TooltipV2
              deactivate={canUseTemplate || (isTrialUser && userInitCount < 3)}
              placement="bottom"
              rootClassName={classes.evalButtonTooltip}
              title={t(
                "Your account does not have permission to create initiatives. Please contact your Gartner Account Manager for assistance."
              )}
            >
              <div>
                <ButtonLoader
                  btnClass="btn-secondary"
                  btnRootClassName={"gx-start-eval-from-market-btn"}
                  disabled={!canUseTemplate && (!isTrialUser || userInitCount >= 3)}
                  onClick={handleClickTemplateButton}
                  isLoading={isLoading}
                  rootClassName="template-use-btn"
                >
                  <span className={classes.buttonLabel}>{t("START EVALUATION")}</span>
                </ButtonLoader>
              </div>
            </TooltipV2>
          </div>
        </div>
      </div>
      <FreeTrialInitCreateModalComponent
        currentInits={userInitCount}
        isOpen={isFreeTrialModalOpen}
        isProcessing={isLoading}
        onClickClose={handleCloseFreeTrialModal}
        onClickConfirm={handleConfirmFreeTrialModal}
      />
    </Fragment>
  );
};

TemplateHeader.propTypes = propTypes;
export default TemplateHeader;
