import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const styles = {
  loaderContainer: {
    minHeight: "200px",
    position: "relative",
  },
  header: {
    color: colorCarbon,
  },
  content: {
    marginTop: "12px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: colorPalette.neutrals.coal.hex,
    maxWidth: "600px",
  },
  details: {
    color: colorCarbon,
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
};

export default styles;
