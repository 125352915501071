import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import {
  CheckmarkIcon,
  ArrowIcon,
  CircleOutlinedEmptyIcon,
  CircleOutlinedPartialFilledIcon,
} from "gx-npm-icons";
import styles from "../styles";

const menuItems = {
  2: {
    icon: (
      <CheckmarkIcon fillPath={colorPalette.status.darkPear.hex} title="" />
    ),
    name: "Complete",
  },
  1: {
    icon: <CircleOutlinedPartialFilledIcon title="" />,
    name: "In progress",
  },
  0: {
    icon: <CircleOutlinedEmptyIcon title="" />,
    name: "Not started",
  },
};

const useStyles = makeStyles(() => styles);
const ProgressStatus = ({ status }) => {
  const classes = useStyles();
  const statusData = menuItems[status];
  return (
    <div className={classNames(classes.checklistStatus, classes.popoverMenu)}>
      <div className={classes.iconContainer}>{statusData.icon}</div>
      <div className={classes.status}>{statusData.name}</div>
      <div className={classes.iconContainer}>
        <ArrowIcon />
      </div>
    </div>
  );
};

export default ProgressStatus;
