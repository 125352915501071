import React, { Fragment, useContext, useEffect } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Loader } from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { getTemplateData } from "../../../context/actions/templateActions";
import styles from "./styles";
import { TEMPLATE_SECTION_OVERVIEW } from "../../../lib/constants";

const propTypes = { templateId: PropType.number, selectedMenu: PropType.bool };
const useStyles = makeStyles(() => styles);
const TemplateOverview = ({ templateId, selectedMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { isLoading, data } = state?.template?.overview || {};

  useEffect(() => {
    if (templateId) {
      getTemplateData(dispatch, { templateId, section: TEMPLATE_SECTION_OVERVIEW });
    }
  }, [dispatch, templateId]);

  return (
    <Fragment>
      <h4 className={classNames(classes.header, selectedMenu && "activeHeader")}>{data?.name}{t(" template Overview")}</h4>
      {isLoading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <Fragment>
          <div className={classes.content}>{data?.description}</div>
          <div className={classes.content}>
            <span>{t("The template provides information such as")}</span>
            <span className={classes.details}>{` ${t("detailed requirements, vendors to consider")}`}</span>
            <span>{t(", a")}</span>
            <span className={classes.details}>{` ${t("comprehensive checklist")}`}</span>
            <span>{` ${t("and a")}`}</span>
            <span className={classes.details}>{` ${t("scorecard")}`}</span>
            <span>
              {` ${t(
                "so that you can have confidence in your final decision. Use this template to manage the evaluation of different"
              )} `}
            </span>
            <span>{data?.name || ""}</span>
            <span>{` ${t("platforms.")}`}</span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

TemplateOverview.propTypes = propTypes;
export default TemplateOverview;
