import { makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import styles from "./styles";
const useStyles = makeStyles(() => styles);
const TruncateText = ({ textStyle, maxLine, text, hideTitle }) => {
  const classes = useStyles();
  return (
    <span
      style={{ WebkitLineClamp: maxLine || 1 }}
      title={!hideTitle && text ? text : null}
      className={classNames(classes.truncateStyle, textStyle)}
    >
      <span>{text}</span>
    </span>
  );
};
export default TruncateText;
