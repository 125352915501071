import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { ExclamationCircledIcon } from "gx-npm-icons";
import styles from "./error-banner.style";

const useStyles = makeStyles(() => styles);
const ErrorBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <ExclamationCircledIcon />
      <span className={classNames("p3", classes.banner)}>{t("Oops... we were unable to load this information.")}</span>
    </div>
  );
};

export default ErrorBanner;
