import { colorPalette, weightPalette } from "gx-npm-common-styles";

const styles = {
  loaderContainer: {
    minHeight: "200px",
    position: "relative",
  },
  header: {
    color: colorPalette.neutrals.carbon.hex,
  },
  content: {
    color: colorPalette.neutrals.coal.hex,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "12px",
    maxWidth: "600px",
  },
  checklistTable: {
    marginTop: "32px",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      zIndex: "1000",
      bottom: "0",
      left: "0",
      backgroundImage: "linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)",
      width: "100%",
      height: "50px",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: "17px",
    border: `1px solid ${colorPalette.neutrals.silver.hex}`,
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.25px",
    color: colorPalette.neutrals.iron.hex,
  },
  iconContainer: {
    width: "20px",
    height: "20px",
    marginRight: "4px",
    "@media (min-width: 1440px)": {
      width: "16px",
    },
  },
  arrowIcon: {
    transform: "rotate(180deg)",
  },
  checklistName: {
    marginLeft: "24px",
    width: "40%",
    "@media (min-width: 1440px)": {
      width: "22%",
    },
    fontWeight: weightPalette.medium.amount,
    fontVariationSettings: weightPalette.medium.variation,
  },
  checklistHeader: {
    fontSize: "16px",
    color: colorPalette.neutrals.carbon.hex,
  },
  checklistStatus: {
    marginLeft: "35px",
    width: "65%",
  },
  popoverMenu: {
    display: "flex",
  },
  status: {
    marginLeft: "12px",
    marginRight: "12px",
    fontWeight: weightPalette.semiBold.amount,
    fontVariationSettings: weightPalette.semiBold.variation,
  },
  countButton: {
    marginTop: "24px",
  },
  noData: {
    paddingTop: "12px",
  },
};

export default styles;
