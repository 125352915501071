import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkIcon, ArrowIcon, CircleOutlinedEmptyIcon, CircleOutlinedPartialFilledIcon } from "gx-npm-icons";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import PropTypes from "prop-types";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flags";
import styles from "../styles";

const menuItems = {
  2: {
    icon: <CheckmarkIcon fillPath={colorPalette.status.darkPear.hex} title="" />,
    name: "Complete",
  },
  1: {
    icon: <CircleOutlinedPartialFilledIcon title="" />,
    name: "In progress",
  },
  0: {
    icon: <CircleOutlinedEmptyIcon title="" />,
    name: "Not started",
  },
};

const useStyles = makeStyles(() => styles);
const propTypes = {
  status: PropTypes.number,
};
const ProgressStatus = ({ status }) => {
  const classes = useStyles();
  const statusData = menuItems[status];
  return (
    <div className={classNames(classes.checklistStatus, classes.popoverMenu)}>
      <div className={classes.iconContainer}>{statusData.icon}</div>
      <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
        <FeatureFlagBooleanOn>
          <TypographyComponent rootClassName={classes.statusGCOM3606} styling={"p3"} color={"iron"} boldness={"semi"}>
            {statusData.name}
          </TypographyComponent>
        </FeatureFlagBooleanOn>
        <FeatureFlagBooleanOff>
          <div className={classes.status}>{statusData.name}</div>
        </FeatureFlagBooleanOff>
      </FeatureFlagBooleanContainer>

      <div className={classes.iconContainer}>
        <ArrowIcon />
      </div>
    </div>
  );
};
ProgressStatus.propTypes = propTypes;
export default ProgressStatus;
