import React from "react";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import {
  CheckmarkCircledIcon,
  CheckmarkDottedCircledOutlineIcon,
} from "gx-npm-icons";
import styles from "../styles";

const darkPear = colorPalette.status.darkPear.hex;
const useStyles = makeStyles(() => styles);
const ChecklistRow = ({ item }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      {item?.complete ? (
        <CheckmarkCircledIcon fillPath={darkPear} />
      ) : (
        <CheckmarkDottedCircledOutlineIcon />
      )}
      <div className={classes.checklistName}>{item?.name}</div>
      <div className={classes.checklistStatus}>{item?.description}</div>
    </div>
  );
};
export default ChecklistRow;
