import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import styles from "./styles";

const menuButtonPropTypes = {
  id: PropTypes.number,
  isSelected: PropTypes.bool,
  menuRef: PropTypes.any,
  onClick: PropTypes.func,
  text: PropTypes.string,
};
const useStyles = makeStyles(() => styles);
const MenuButton = ({ id, isSelected, menuRef, onClick, text }) => {
  const classes = useStyles();
  return (
    <button
      className={classNames(classes.menuItem, isSelected && "selected")}
      onClick={() => handleEvent(onClick, menuRef, id)}
    >
      <div className={classNames(classes.btnDisplay)}>
        <div className={classNames(classes.btnChild, classes.btnBorder)}></div>
        <div className={classNames(classes.btnChild, classes.btnName)}>
          {text}
        </div>
      </div>
    </button>
  );
};
MenuButton.propTypes = menuButtonPropTypes;

const menuBarPropTypes = {
  handleMenuChange: PropTypes.func,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      menuRef: PropTypes.any,
      text: PropTypes.string,
    })
  ),
  selectedMenu: PropTypes.number,
};
const Menubar = ({ handleMenuChange, menu, selectedMenu }) => {
  const classes = useStyles();
  return (
    <div className={classes.menuContainer}>
      {menu &&
        menu.map((item) => (
          <MenuButton
            isSelected={selectedMenu === item.id}
            id={item.id}
            key={item.id}
            menuRef={item.menuRef}
            onClick={handleMenuChange}
            text={item.text}
          />
        ))}
    </div>
  );
};

Menubar.propTypes = menuBarPropTypes;
export default Menubar;
