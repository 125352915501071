import React, { Fragment } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TypographyComponent } from "gx-npm-ui";
import TeamScore from "../../../assets/images/teamScore.svg";
import VendorQuestionnaire from "../../../assets/images/vendorQuestionnaire.svg";
import styles from "./styles";

const propTypes = { selectedMenu: PropType.bool };
const useStyle = makeStyles(() => styles);
const EvaluationActivities = ({ selectedMenu = false }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Fragment>
      <h4 className={classNames(classes.header, selectedMenu && "activeHeader")}>{t("Evaluation activities")}</h4>
      <Fragment>
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            <TypographyComponent rootClassName={classes.contentHeader} type="p2" boldness="semi">
              {t("Vendor questionnaires")}
            </TypographyComponent>
            <TypographyComponent rootClassName={classes.contentText} type="p3" boldness="regular">
              {t(
                "Request information from vendors and view their responses to evaluate surveyed vendors inside the application."
              )}
            </TypographyComponent>
            <img src={VendorQuestionnaire} alt="vendor questionnaire svg" />
          </div>
          <div className={classes.rightContainer}>
            <TypographyComponent rootClassName={classes.contentHeader} type="p2" boldness="semi">
              {t("Score with your team")}
            </TypographyComponent>
            <TypographyComponent rootClassName={classes.contentText} type="p3" boldness="regular">
              {t(
                "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
              )}
            </TypographyComponent>
            <img src={TeamScore} alt="teamScore svg" />
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

EvaluationActivities.propTypes = propTypes;
export default EvaluationActivities;
